import React from "react"
import PropTypes from "prop-types"
import tw, { css } from "twin.macro"
import Icon from "./icon"
import ArrowDown from "../images/svgs/arrow-down.svg"

const AnchorNavigation = ({ anchors }) => {
  const superStyles = tw`font-bold`
  const subStyles = tw`pl-5 ml-0.5 font-regular `
  return (
    <nav
      tw="top-24 -mx-4 bg-sand-500 px-4 py-4 text-sm sm:-mx-10 sm:px-10 lg:sticky lg:top-40 lg:mx-0 lg:bg-transparent lg:px-0 lg:py-0 xl:top-32"
      aria-label="Jumpmarks"
    >
      <ol>
        {anchors.map((anchor, index) => (
          <li key={`anchor-${index}`}>
            <a
              href={`#${anchor.slug}`}
              css={anchor.hirachyAnchor === "super" ? superStyles : subStyles}
              tw="flex items-start hyphens-auto py-3 text-sm word-break lg:py-2"
              title="Zum Inhalt springen"
            >
              {anchor.hirachyAnchor === "super" && (
                <div>
                  <Icon svg={ArrowDown} tw="mr-2 mt-1 h-3.5 w-3.5" />
                </div>
              )}
              {anchor.titleAnchor}
            </a>
          </li>
        ))}
      </ol>
    </nav>
  )
}

AnchorNavigation.propTypes = {
  anchors: PropTypes.array.isRequired,
}

export default AnchorNavigation
